import { ApolloClient, InMemoryCache } from "@apollo/client";
const { API_URL } = require('./client-url');

// const suffix = process.env.NEXT_PUBLIC_ZONE_SUFFIX ? process.env.NEXT_PUBLIC_ZONE_SUFFIX.substr(1) : ''
// export const API_URL = suffix ? `https://${suffix}/admin/api` : 'http://192.168.2.130:4006/admin/api'

console.log('Constructing admin apollo client for', API_URL)

const client = new ApolloClient({
  ssrMode: typeof window === 'undefined',
  uri: API_URL,
  cache: new InMemoryCache(),
});

export default client;
