import React, {useEffect} from 'react'
import '../styles/globals.css'
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import client from '../apollo/apollo-client'
import { ApolloProvider } from '@apollo/react-hooks'
import {useRouter} from "next/router";

function MyApp({ Component, pageProps }) {
  const router = useRouter()
  const asPath = router?.asPath

  useEffect(() => {
    if (asPath !== '/') {
      const metaTitle = document.getElementById('meta-title')
      const metaOrTitle = document.getElementById('meta-og-title')
      const metaTwitterTitle = document.getElementById('meta-twitter-title')
      const metaDescription = document.getElementById('meta-description')
      const metaOgDescription = document.getElementById('meta-og-description')
      const metaTwitterDescription = document.getElementById('meta-twitter-description')
      const metaOgImage = document.getElementById('meta-og-img')
      metaTitle?.remove()
      metaOrTitle?.remove()
      metaTwitterTitle?.remove()
      metaDescription?.remove()
      metaOgDescription?.remove()
      metaTwitterDescription?.remove()
      metaOgImage?.remove()
    }
  }, [asPath])
  
  return(
    <ApolloProvider client={client}>
      <Component {...pageProps} />
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-203638305-1"></script>
      <script src="/scripts/hotjsr.js"></script>
      {
        process.env.NODE_ENV === 'production' && (
          <>
            <script src="https://d7a97ajcmht8v.cloudfront.net/production/app.js"></script>
            <script src="/scripts/widget.js"></script>
          </>
        )
      }
      <noscript>
        <img height="1" width="1" style={{display: 'none'}} src="https://www.facebook.com/tr?id=963116997801756&ev=PageView&noscript=1"/>
      </noscript>
      <script src="/files/admin/custom-js.js"></script>
      <link rel="stylesheet" src="/files/admin/custom-css.css"></link>
    </ApolloProvider>
  )
}

export default MyApp
